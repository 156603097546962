import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tag = _resolveComponent("el-tag")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_tag, {
      class: "ml-2",
      type: _ctx.mapType.type
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.mapType.text), 1)
      ]),
      _: 1
    }, 8, ["type"])
  ]))
}