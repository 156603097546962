
import { RECONCILE_STATUS } from '@/api/reconcile/reconcile.type'
import { computed, defineComponent } from 'vue'

export default defineComponent({
	name: 'ReconcileStatus',
	props: {
		status: {
			type: String,
			default: '',
		}
	},
	setup (props) {
		const mapType = computed(() => {
			if (props.status === RECONCILE_STATUS.COMPLETED) {
				return {
					type: 'success',
					text: 'ชำระแล้ว'
				}
			}
			return {
				type: '',
				text: 'ระบบค้างชำระ'
			}
		})
		return {
			mapType,
		}
	}
})
