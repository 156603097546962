
import {
	generateReconcilePdf,
	getReconcileAllShop,
	reconcileTransferUpdate,
	reconcileTransaction,
	confirmReconcile,
} from '@/api/reconcile/reconcile.api'
import dayjs from '@/utils/dayjs'
import { ElNotification } from 'element-plus'
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import xlsx from 'xlsx'
import { autofitColumns } from '@/utils/xlsx'
import ReconcileStatus from '@/components/reconcile/ReconcileStatus.vue'
import { AnyFunction } from 'element-plus/es/utils/types'
import { RECONCILE_STATUS } from '@/api/reconcile/reconcile.type'

export default defineComponent({
	name: 'ReconcileShop',
	components: {
		ReconcileStatus,
	},
	setup() {
		const route = useRoute()
		const router = useRouter()
		const reconcileTableRef = ref()
		const state = reactive({
			isFetching: false,
			codTotal: 0,
			shop: [],
			pagination: {
				offset: 1,
				limit: 9999999,
				pages: 0,
			},
			tabInfo: {
				completedCount: 0,
				reconciledCount: 0,
			},
			shopSelected: [],
			submittingTransfer: false,
			isGenerateExcelForPayment: false,
			isConfirmGenerateReconcile: false,
			isConfirmGenerateReconcileDone: false,
		})
		const shopSelectedIds = computed(() =>
			state.shopSelected.map((shop: any) => shop._id)
		)
		const fetchReconcileShop = () => {
			state.isFetching = true
			getReconcileAllShop({
				limit: state.pagination.limit,
				offset: state.pagination.offset,
				reconcileId: route.params.reconcileId as string,
			})
				.then(({ data: { data, pagination, tabInfo } }: any) => {
					state.shop = data.map((each: any) => ({
						...each,
						...each.shop,
						isDownloading: false,
					}))
					state.pagination = {
						...state.pagination,
						pages: pagination.pages,
					}
					state.tabInfo = {
						completedCount: tabInfo?.completedCount,
						reconciledCount: tabInfo?.reconciledCount,
					}
				})
				.finally(() => {
					state.isFetching = false
				})
		}

		const updateReconcile = async () => {
			// TODO : something
			try {
				state.submittingTransfer = true
				const payload = {
					reconcileId: route.params.reconcileId as string,
					shops: shopSelectedIds.value,
				}
				await reconcileTransferUpdate(payload)
				ElNotification({
					type: 'success',
					title: 'สำเร็จ',
					message: 'อัพเดท Reconcile สำเร็จ',
				})
				// await router.push('/reconcile')
				await fetchReconcileShop()
			} catch (error) {
				console.error(error)
			} finally {
				state.submittingTransfer = false
			}
		}

		const onSelectionHandle = (rowData: []) => {
			state.shopSelected = rowData
		}

		const downloadReconcilePdf = (row: any) => {
			row.isDownloading = true
			const reconcileId = route.params.reconcileId as string

			generateReconcilePdf(reconcileId, row._id)
				.then((response) => {
					const url = window.URL.createObjectURL(
						new Blob([response.data])
					)
					const link = document.createElement('a')
					link.href = url
					const filename = `COD_Remittance_${dayjs().format(
						'DD-MM-YYYY'
					)}_${row.shop?.name}.pdf`
					link.setAttribute('download', filename) //or any other extension
					document.body.appendChild(link)
					link.click()
					link.remove()
				})
				.finally(() => (row.isDownloading = false))
		}

		const excelTransferSCB = async ({ isScb = true }) => {
			state.isGenerateExcelForPayment = true
			try {
				const params = {
					reconcileId: route.params.reconcileId as string,
					isScb,
					shops: shopSelectedIds.value as any,
				}
				const { data: response } = await reconcileTransaction(params)

				const url = window.URL.createObjectURL(new Blob([response]))
				const link = document.createElement('a')
				link.href = url
				let filename = `scb_tx_${dayjs().format('YYYYMMDD')}.txt`
				if (!isScb) {
					filename = `another_tx_${dayjs().format('YYYYMMDD')}.txt`
				}
				link.setAttribute('download', filename) //or any other extension
				document.body.appendChild(link)
				link.click()
				link.remove()
			} catch (error) {
				console.error(error)
			} finally {
				state.isGenerateExcelForPayment = false
			}
		}

		const confirmGenerateReconcile = async () => {
			state.isConfirmGenerateReconcile = true
			try {
				const { data: response } = await confirmReconcile({
					reconcileId: route.params.reconcileId as string,
					shops: shopSelectedIds.value as any,
				})

				const url = window.URL.createObjectURL(new Blob([response]))
				const link = document.createElement('a')
				link.href = url
				const filename = `confirm_download_${dayjs().format(
					'YYYYMMDD'
				)}.txt`

				link.setAttribute('download', filename) //or any other extension
				document.body.appendChild(link)
				link.click()
				link.remove()
				state.isConfirmGenerateReconcileDone = true
			} catch (error) {
				//
			} finally {
				state.isConfirmGenerateReconcile = false
			}
		}

		const isRowCanSelectAble = (row: any) => {
			return !row.isHolding && row.status !== RECONCILE_STATUS.COMPLETED
		}

		onMounted(() => {
			fetchReconcileShop()
		})

		return {
			state,
			fetchReconcileShop,
			onSelectionHandle,
			updateReconcile,
			downloadReconcilePdf,
			excelTransferSCB,
			confirmGenerateReconcile,
			isRowCanSelectAble,
			reconcileTableRef,
		}
	},
})
